module.exports=[
  [ 911056, 911058, 911098, 911100, 911102, 911112, 911114, 911116, 911398, 911400, 911402, 911404, 911406, 911408, 911410, 911412, 911414, 911416, 911418, 911420, 911422, 911424, 911426, 911428, 911430, 911432, 911434, 911436, 911438, 911440, 911442, 911444, 911446, 911448, 911450, 911452, 911454, 911456, 911458, 911460, 911462, 911464, 911466, 911492, 911494, 911496, 911504, 911506, 911508, 911530, 911534, 911546, 911548, 911550, 911588, 911590, 911644, 911646, 911658, 911660, 911672, 911674, 911790, 911792, 911794, 911800, 911804, 911812, 911826, 911900, 911916, 911920, 911922, 911924, 911926, 911928, 911902, 911904, 911906, 911908, 911910, 911912, 911914, -72092, -98174, -392935, 392873, 425926, 425953, 425958, 906398, 906400, 906158, 906164, 906166, 906168, 905756, 905758, 905760, 351037, 351039, 351229, 351231, 351233, 351235, 351099, 351105, 351107, 351113, 351119, 351151, 351183, 351215, 351247, 351279, 351141, 351143, 351149, 351165, 351167, 351191, 351197, 351199, 351201, 351213, 351227 ],
  [ 911090, 911092, 911094, 911096, 911482, 911484, 911486, 911488, 911490, 906394, 906396, 906408, 906410, 906404, 906406, 906418, 906420, 351185, 351187, 351189, 351203 ],
  [ 910952, 910954, 910956, 910960, 911640, 911642, 911652, 911662, 911888, 911890, 911892, 911894, 911896, 911898, 905782, 905784, 905786, 905788, 905792, 905794, 906020, 906050, 906080, 906110, 351083, 351109, 351111, 351117, 351153, 351155, 351179, 351211, 351243, 351181 ],
  [ 910950, 910958, 910962, 911048, 911050, 911052, 911054, 911062, 911064, 911066, 911068, 911076, 911078, 911080, 911082, 911060, 911074, 911084, 911086, 911088, 911468, 911510, 911524, 911538, 911552, 911566, 911636, 911638, 911648, 911650, 911664, 911666, 911668, 911670, 911676, 911796, 911818, 911832, 911846, 911860, 911874, 911856, 911858, 351045, 351047, 351101, 351145, 351147, 351163, 351169, 351171, 351173, 351175, 351177, 351205, 351207, 351209, 351249, 351251, 351253, 351255, 351237, 351239, 351241 ],
  [ 910930, 910934, 911210, 911212, 911214, 911756, 911760, 911784, 911786, 911798, 911802, 351049, 351051, 351053, 351055, 351057, 351059, 351061, 351063, 351073 ],
  [ 911582, 911584, 911586, 911592, 351121, 351123, 351125, 351127 ],
  [ 911512, 911514, 911516, 351131, 351157, 351159 ],
  [ 911470, 911472, 911474, 911476, 911478, 911480, 911834, 911836, 911838, 911844, 351195, 351217, 351219, 351221, 351223 ],
  [ 910922, 911188, 911190, 911192, 911194, 911202, 911204, 911206, 911208, 911216, 911218, 911220, 911222, 911200, 911224, 911226, 911228, 911744, 911746, 911762, 911764, 911766, 911768, 911776, 911778, 911780, 911782, 911774, 911788, 351035, 351041, 351081, 351133, 351135, 351137, 351139 ],
  [ 911742, 351067, 351075, 351077, 351079 ],
  [ 911126, 911128, 911168, 911170, 911172, 911182, 911184, 911186, 911174, 911180, 912064, 912066, 912078, 912080, 912092, 912094, 912164, 912166, 392883, 906426, 906428, 905876, 905878, 905880, 351293, 351295, 351485, 351487, 351489, 351491, 351355, 351361, 351363, 351369, 351375, 351407, 351439, 351471, 351503, 351535, 351397, 351399, 351405, 351421, 351423, 351447, 351453, 351455, 351457, 351469, 351483, 351387, 351413, 351415 ],
  [ 911160, 911162, 911164, 911166, 912154, 912156, 912158, 912160, 912162, 906422, 906424, 906436, 906438, 906432, 906434, 906446, 906448, 351441, 351443, 351445, 351459 ],
  [ 911944, 911946, 911948, 911950, 912112, 912114, 912116, 912118, 912168, 912170, 912172, 912174, 906186, 906192, 906194, 906196, 351493, 351495, 351497 ],
  [ 910936, 910938, 910940, 910942, 910946, 912024, 912030, 912044, 912046, 912054, 912060, 912062, 912072, 912082, 905902, 905904, 905906, 905908, 905912, 905914, 351339, 351365, 351367, 351373, 351409, 351411, 351435, 351467, 351499, 351437 ],
  [ 910944, 911118, 911120, 911122, 911124, 911132, 911134, 911136, 911138, 911146, 911148, 911150, 911152, 911130, 911144, 911154, 911156, 911158, 911958, 912028, 912042, 912056, 912070, 912084, 912098, 912058, 912068, 912086, 912088, 912090, 912096, 912126, 912140, 912196, 351301, 351303, 351357, 351401, 351403, 351419, 351425, 351427, 351429, 351431, 351433, 351461, 351463, 351465, 351505, 351507, 351509, 351511 ],
  [ 910916, 910920, 912260, 912264, 351305, 351307, 351309, 351311, 351313, 351315, 351317, 351319, 351329 ],
  [ 912210, 912212, 912214, 912216, 912218, 912220, 912222, 351377, 351379, 351381, 351383 ],
  [ 912148, 912150, 912152, 351451, 351473, 351475, 351477, 351479 ],
  [ 911258, 911260, 911262, 911264, 911272, 911274, 911276, 911278, 911286, 911288, 911290, 911292, 911270, 911294, 911296, 911298, 911678, 911692, 912238, 912240, 912242, 912244, 912250, 912294, 912296, 912298, 912300, 260475, 260477, 260479, 260481, 260489, 260491, 260493, 260495, 260501, 260503, 260531, 260533, 260535, 260537, 351291, 351297, 351337, 351389, 351391, 351393, 351395 ],
  [ 912280, 912284, 912286, 912288, 912290, 351323, 351331, 351333, 351335 ],
  [ 912014, 912016, 912018, 912020, 912022, 912026, 351341, 351343, 351345, 351347, 351349, 351351 ],
  [ 911532, 911536 ],
  [ 911540, 911542, 911544 ],
  [ 911654, 911656 ],
  [ 911070, 911072, 911498, 911500, 911502, 911526, 911528, 911820, 911822, 911862, 911864, 911866, 911868, 911870, 911872, 911884, 911886, 911918, 906412, 906414 ],
  [ 911104, 911106, 911108, 911110, 906402, 906416 ],
  [ 911848, 911850, 911852, 911876, 911878, 911880, 911882 ],
  [ 911854 ],
  [ 910924, 910926, 910928 ],
  [ 911570, 911572 ],
  [ 911562, 911564 ],
  [ 911556, 911558, 911560 ],
  [ 911554 ],
  [ 911520, 911522 ],
  [ 911518 ],
  [ 911806, 911808, 911810 ],
  [ 911814, 911816 ],
  [ 911828, 911830 ],
  [ 911840, 911842 ],
  [ 911196, 911198, 911238, 911240, 911252, 911254, 911770, 911772 ],
  [ 911230, 911232, 911234, 911236 ],
  [ 911244, 911246, 911248, 911250, 911256 ],
  [ 911748, 911750, 911752, 911754 ],
  [ 911734, 911736, 911738, 911740 ],
  [ 911952, 911954, 911956 ],
  [ 911176, 911178, 906430 ],
  [ 911140, 911142, 911968, 911970, 912048, 912050, 912052, 906440, 906442 ],
  [ 912204, 912206, 912208 ],
  [ 912182, 912184, 912186, 912188, 912190, 912192, 912194 ],
  [ 912120, 912122, 912124 ],
  [ 912142, 912144, 912146 ],
  [ 912176, 912178, 912180 ],
  [ 911960, 911962, 911964, 911966 ],
  [ 911930, 911932, 911934, 911936, 911938, 911940, 911942 ],
  [ 911994, 911998, 912000, 912002, 912004, 912006, 912008, 912010, 912012 ],
  [ 911986, 911988, 911990, 911992, 911996 ],
  [ 912108, 912110 ],
  [ 912128, 912130, 912132, 912134, 912136, 912138 ],
  [ 912100, 912102, 912104 ],
  [ 912032, 912034, 912036 ],
  [ 912038, 912040 ],
  [ 911266, 911268, 911310, 911312, 911324, 911688, 911702, 911716, 912234, 912304, 260541 ],
  [ 911280, 911284, 911714, 911718, 912248, 912266, 912268, 912270, 912272, 912274, 912302, 912306 ],
  [ 912258 ],
  [ 910918, 911282, 912252, 912254, 912256, 912262 ],
  [ 260483, 260485, 260487, 260499, 260505, 260507, 260509, 260511, 260513, 260515, 260525, 260527, 260529, 260539, 260543 ],
  [ 911300, 911302, 911304, 911306, 911308 ],
  [ 911314, 911316, 911318, 911320, 911322, 911326 ],
  [ 911680, 911682, 911684, 911686, 911694, 911696, 911698, 911700, 911690, 911704, 911708, 911710, 911712 ],
  [ 912224, 912226, 912228, 912230, 912232, 912236 ],
  [ 912282, 912292 ]
]
