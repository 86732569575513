module.exports=[
  [ 906848, 906850, 906852, 906854 ],
  [ 906856, 906858, 906860, 906862 ],
  [ 906864, 906866, 906868, 906870 ],
  [ 906872, 906874, 906876, 906878 ],
  [ 906880, 906882, 906884, 906886 ],
  [ 906888, 906890, 906892, 906894 ],
  [ 906896, 906898, 906900, 906902 ],
  [ 906904, 906906, 906908, 906910 ],
  [ 906912, 906914, 906916, 906918 ],
  [ 906920, 906922, 906924, 906926 ],
  [ 906928, 906930, 906932, 906934 ],
  [ 906936, 906938, 906940, 906942 ],
  [ 906944, 906946, 906948, 906950 ],
  [ 906952, 906954, 906956, 906958 ],
  [ 906960, 906962, 906964, 906966 ],
  [ 906968, 906970, 906972, 906974 ]
]
